import { use, type PostProcessorModule } from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import { APP_ENV } from '#lib/utils/environment';
import { env } from '../../../../helpers/env';
import { type I18nContent } from './types';

const DISABLE_I18NEXT_LOGGER = env('PORTAL_DISABLE_I18NEXT_LOGGER', 'true') === 'true';

type InitializeI18nFn = {
  applicationName: string;
  useOptimizelyBackend: boolean;
  resources?: I18nContent;
  userLang?: string;
};
let initialized = false;

const isDebugMode = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const lng = searchParams.get('lng');

  return lng === 'debug' || lng === 'review';
};

const getBackendConfig = (applicationName: string) => {
  const baseUrl = env('PORTAL_AWS_S3_ASSETS_URL');

  return {
    loadPath: `${baseUrl}/texts/${applicationName}/{{lng}}.json`,
    customHeaders: {
      accept: 'application/json',
    },
    crossDomain: true,
  };
};

export const initializeI18n = async ({
  applicationName,
  useOptimizelyBackend,
  resources,
  userLang,
}: InitializeI18nFn) => {
  if (initialized) return;

  const textDebugMode = isDebugMode();

  const debugPostProcessor: PostProcessorModule = {
    type: 'postProcessor',
    name: 'debugMode',

    process: (value: string, key: string) => {
      if (textDebugMode) {
        return `${value} (${applicationName} / ${key})`;
      } else {
        return value;
      }
    },
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const i18n = use(detector).use(initReactI18next).use(debugPostProcessor);

  if (useOptimizelyBackend) {
    i18n.use(HttpBackend);
  }
  const missing: Record<string, string> = {};

  const backendConfig = getBackendConfig(applicationName);

  await i18n.init({
    supportedLngs: ['en', 'da', 'sv', 'fi', 'pl', 'no', 'debug'],
    postProcess: 'debugMode',
    debug: !DISABLE_I18NEXT_LOGGER,
    nonExplicitSupportedLngs: true,
    fallbackLng: 'en',
    lng: userLang,
    returnEmptyString: false,
    saveMissing: true,
    missingKeyHandler: (lngs, ns, key) => {
      if (APP_ENV === 'production') {
        return;
      }
      missing[key] = '';

      console.log(missing);

      return key;
    },
    load: 'languageOnly',
    keySeparator: '.',
    resources: useOptimizelyBackend ? undefined : resources,
    react: {
      useSuspense: false,
    },
    partialBundledLanguages: false,
    backend: backendConfig,
  });
  initialized = true;
};
